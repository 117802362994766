<template>
  <app-page-wrapper>
    <blog-highlighted-post v-if="highlightedPost" :post="highlightedPost" />

    <section class="py-8 @container md:py-12">
      <header
        class="mb-8 flex flex-col justify-between gap-2 @lg:flex-row @lg:items-center"
      >
        <h2 class="font-title text-xl/normal">Tous nos articles</h2>
        <app-form-input-text
          v-model="query"
          class="min-w-[350px]"
          label="Rechercher"
          prepend-icon="ph:magnifying-glass"
        />
      </header>

      <transition-group
        class="grid gap-x-6 gap-y-8 @lg:grid-cols-2 @3xl:grid-cols-3 @6xl:grid-cols-4"
        enter-active-class="transition"
        enter-from-class="opacity-0 translate-y-1"
        leave-active-class="transition"
        leave-to-class="opacity-0 translate-y-1"
        move-class="transition"
        tag="ul"
      >
        <li v-for="post in posts" :key="post.id">
          <blog-post-card class="h-full" :post="post" title-level="h2" />
        </li>
      </transition-group>
    </section>
  </app-page-wrapper>
</template>

<script lang="ts" setup>
import BlogHighlightedPost from "~/pages/blog/_includes/components/blog-highlighted-post.vue";
import { useBlogPostSearchableList } from "~/pages/blog/_includes/composables/blog-post-searchable-list.hook";

definePageMeta({
  noFaq: true,
});

useSeoMeta({
  title: "Blog - le meilleur de la formation professionnelle",
  description:
    "Découvrez les dernières tendances en matière formation professionnelle et les meilleurs pratiques de nos trainers.",
});

useSchemaOrg([
  defineWebPage({
    "@type": "CollectionPage",
  }),
]);

const { query, posts, highlightedPost } = await useBlogPostSearchableList();
</script>
